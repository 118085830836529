import "./Sidebar-Direita.scss";
import React from "react";

function SidebarDireita(props) {
  return (
    <div
      className="sidenav-direita-bg"
      style={{ backgroundColor: props.bg }}
    ></div>
  );
}

export default SidebarDireita;
