import React from 'react';
import images from './Assets/imageImports'; // Importando as imagens dinamicamente

function ImageGrid({ onViewMore }) {
  const imageList = [
    { src: images.capa1, alt: 'capa1', text: '1° Edição - 2008', edition: 1 },
    { src: images.capa2, alt: 'capa2', text: '2° Edição - 2009', edition: 2 },
    { src: images.capa3, alt: 'capa3', text: '3° Edição - 2010', edition: 3 },
    { src: images.capa4, alt: 'capa4', text: '4° Edição - 2012', edition: 4 },
    { src: images.capa5, alt: 'capa5', text: '5° Edição - 2013', edition: 5 },
    { src: images.capa6, alt: 'capa6', text: '6° Edição - 2014', edition: 6 },
    { src: images.capa7, alt: 'capa7', text: '7° Edição - 2017', edition: 7 },
    { src: images.capa8, alt: 'capa8', text: '8° Edição - 2020', edition: 8 },
  ];

  return (
    <div className="image-container">
      {imageList.map((image, index) => (
        <div key={index} className="image-wrapper">
          <img src={image.src} alt={image.alt} />
          <div className="image-text" onClick={() => onViewMore(image.edition)}>{image.text}</div>
        </div>
      ))}
    </div>
  );
}

export default ImageGrid;

