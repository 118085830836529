import "./Sidebar.scss";
import logo from "./LOGO6.png";
import { useState, useEffect } from "react";
import Sub1 from "../Subnavs/Sub-1";
import Sub2 from "../Subnavs/Sub-2";
import Sub3 from "../Subnavs/Sub-3";
import Eye from "../Eye/Eye";

function Sidebar(props) {
  const [subOn, showSub] = useState("show-0");
  const [currentSection, setCurrentSection] = useState(window.location.hash);
  useEffect(() => {
    const handleHashChange = () => {
      setCurrentSection(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <div className="sidenav-wrapper">
      <div className="sidenav">
        <a
          className={
            props.placeOn === "pagina-vertical-3"
              ? "bg-azul color-b nav nav-3"
              : "nav nav-3"
          }
          id="prim-botao"
          onMouseLeave={() => showSub("show-0")}
          onMouseEnter={() => showSub("show-3")}
          onClick={() => props.scrollToSection("ter", "down-1")}
        >
          <span>Chega mais</span>
        </a>
        <a
          className={
            props.placeOn === "pagina-vertical-2"
              ? "bg-rosa nav nav-2"
              : "nav nav-2"
          }
          onMouseLeave={() => showSub("show-0")}
          onMouseEnter={() => showSub("show-2")}
          onClick={() => props.scrollToSection("seg", "down-1")}
        >
          <span>Nossas memórias</span>
        </a>
        <a
          className={
            props.placeOn === "pagina-vertical-1"
              ? "bg-verde color-b nav nav-1"
              : "nav nav-1"
          }
          onMouseLeave={() => showSub("show-0")}
          onMouseEnter={() => showSub("show-1")}
          onClick={() => props.scrollToSection("primeiro", "down-1")}
        >
          <span> Nosso rolê</span>
        </a>
        <a href="#" id="logo">
          <Eye />
        </a>
      </div>
      <div className="subnavs">
        <Sub1
          scrollToSection={props.scrollToSection}
          setSecondaryIconColor={props.setSecondaryIconColor}
          setSecondaryColor={props.setSecondaryColor}
          subOn={subOn}
          showSub={showSub}
          bg={props.bg}
          currentSection={currentSection.replace("#", "")}
          placeOn={props.placeOn}
        />
        <Sub2
          scrollToSection={props.scrollToSection}
          setSecondaryIconColor={props.setSecondaryIconColor}
          setSecondaryColor={props.setSecondaryColor}
          subOn={subOn}
          showSub={showSub}
          bg={props.bg}
          currentSection={currentSection.replace("#", "")}
          placeOn={props.placeOn}
        />
        <Sub3
          scrollToSection={props.scrollToSection}
          setSecondaryIconColor={props.setSecondaryIconColor}
          setSecondaryColor={props.setSecondaryColor}
          subOn={subOn}
          showSub={showSub}
          bg={props.bg}
          currentSection={currentSection.replace("#", "")}
          placeOn={props.placeOn}
        />
      </div>
    </div>
  );
}

export default Sidebar;
