// AnimatedPixels.js
import React from "react";
import "./AnimatedPixels.scss";

const AnimatedPixels = ({ amount = 100 }) => {
  function Pixel({ left }) {
    const animationDelay = Math.random() * 5;

    const style = {
      left: `${left}%`,
      bottom: "-10px",
      animationDelay: `${animationDelay}s`,
    };

    return <div className="pixel" style={style}></div>;
  }
  return (
    <div className="pixels-container">
      {Array.from({ length: 100 }).map((_, index) => (
        <Pixel key={index} left={index} />
      ))}
    </div>
  );
};

export default AnimatedPixels;
