import React, { useState, useEffect, useRef } from 'react';
import './vertical.scss';
import filme from './Assets/filme.png';

const Edition = ({ edition, images, description, signatures, onBack, showBackButton, titles = [] }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [showFullGallery, setShowFullGallery] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageOrientation, setImageOrientation] = useState(null);
  const [playAnimation, setPlayAnimation] = useState(false);


  const galleryRef = useRef(null);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImageOrientation(naturalHeight > naturalWidth ? 'portrait' : 'landscape');
  };

  const openImageCarousel = (index) => {
    setSelectedImageIndex(index);
    setSelectedImage(images[index].large);
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % images.length;
      setSelectedImage(images[newIndex].large);
      return newIndex;
    });
  };

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + images.length) % images.length;
      setSelectedImage(images[newIndex].large);
      return newIndex;
    });
  };

  const toggleFullInfo = () => setShowFullInfo(!showFullInfo);
  const toggleFullGallery = () => setShowFullGallery(!showFullGallery);
  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageIndex(null);
  };

  // Slider functions
  const nextSlide = () => setSliderIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  const prevSlide = () => setSliderIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  const calculateImagePosition = (index) => {
    const positions = ['0.9%', '25.6%', '50.4%', '75.2%'];
    return positions[index] || '0%';
  };

  const imagesPerSlide = 4;
  const groupedImages = [];
  for (let i = 0; i < images.length; i += imagesPerSlide) {
    groupedImages.push(images.slice(i, i + imagesPerSlide));
  }

  const totalSlides = groupedImages.length;

  const editionYears = {
    0: " ",
    1: "2008",
    2: "2009",
    3: "2010",
    4: "2012",
    5: "2013",
    6: "2014",
    7: "2017",
    8: "2020"
  };

  return (
    <div className="edition-container">
      <div className="vertical-title bg-azul">
        <h3>{edition}º Edição - {editionYears[edition]}</h3>
      </div>

      {/* Condicional para ocultar o slider e o restante do conteúdo */}
      {!showFullGallery && (
        <>
          {/* Slider de imagens com fundo do filme */}
          <div className="slider-wrapper">
            <div className="slider-container" style={{ transform: `translateX(-${sliderIndex * 100}%)` }}>
              {groupedImages.map((group, slideIndex) => (
                <div className="filme-container" key={slideIndex}>
                  {group.map((imageObj, imageIndex) => {
                    const globalImageIndex = imageIndex + slideIndex * imagesPerSlide;
                    const title = titles[globalImageIndex] || `Imagem ${globalImageIndex + 1}`;
                    const isSuperior = globalImageIndex % 2 === 0;
                    return (
                      <React.Fragment key={globalImageIndex}>
                        <div
                          className={`titulo-${isSuperior ? 'superior' : 'inferior'}`}
                          style={{ left: calculateImagePosition(imageIndex) }}
                        >
                          {title}
                        </div>
                        <img
                          src={imageObj.small}
                          alt={`Imagem ${globalImageIndex + 1}`}
                          className="imagem-posicionada-edition lazy-image"
                          style={{ left: calculateImagePosition(imageIndex) }}
                          onClick={() => openImageCarousel(globalImageIndex)}
                          loading="lazy"
                        />
                      </React.Fragment>
                    );
                  })}
                  <img src={filme} alt="Fundo do filme" className="filme-preto" loading="lazy" />
                </div>
              ))}
            </div>
            {/* Botões do slider */}
            <button className="slider-button left" onClick={prevSlide}>
              {"<"}
            </button>
            <button className="slider-button right" onClick={nextSlide}>
              {">"}
            </button>
          </div>

          {/* Conteúdo da edição com descrição e assinaturas */}
          <div className={`edition-info ${showFullInfo ? 'expanded' : 'collapsed'}`}>
            <p>{description}</p>

            {/* Assinaturas */}
            {signatures && (
              <div className="signatures">
                {signatures.map((sig, index) => (
                  <div className="title-name-container" key={index}>
                    <h3>{sig.title}</h3>
                    <p>{sig.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Botão para alternar "Ver mais" e "Ver menos" */}
          <button onClick={toggleFullInfo} className="toggle-button">
            {showFullInfo ? 'Ver menos' : 'Ver mais'}
          </button>
        </>
      )}

      {/* Botão para ver galeria completa */}
      <button onClick={toggleFullGallery} className="toggle-button" style={{ marginBottom: 10 }}>
        {showFullGallery ? 'Ocultar galeria completa' : 'Ver galeria completa'}
      </button>

       {/* Galeria completa, visível ao clicar no botão */}
       {showFullGallery && (
        <div className="gallery-container" ref={galleryRef}>
          {groupedImages.map((group, groupIndex) => (
            <div className="filme-container" key={groupIndex}>
              {group.map((imageObj, imageIndex) => {
                const isSuperior =
                  (imageIndex + groupIndex * imagesPerSlide) % 2 === 0;
                const globalImageIndex = imageIndex + groupIndex * imagesPerSlide;
                const title = titles[globalImageIndex] || `Imagem ${globalImageIndex + 1}`; // Título dinâmico

                // Cálculo de posições iniciais para a animação
                const initialPositions = [
                  { x: '-100%', y: '0%', angle: '-45deg' },  // Esquerda
                  { x: '0%', y: '-100%', angle: '45deg' },   // Topo
                  { x: '100%', y: '0%', angle: '135deg' },   // Direita
                  { x: '0%', y: '100%', angle: '-135deg' },  // Inferior
                ];
                const positionIndex = globalImageIndex % initialPositions.length;
                const { x: initialX, y: initialY, angle: initialAngle } = initialPositions[positionIndex];

                return (
                  <React.Fragment key={globalImageIndex}>
                    <div
                      className={`titulo-${isSuperior ? 'superior' : 'inferior'}`}
                      style={{ left: calculateImagePosition(imageIndex) }}
                    >
                      {title}
                    </div>
                    <img
                      src={imageObj.small}
                      alt={`Imagem ${globalImageIndex + 1}`}
                      className={`imagem-posicionada-edition lazy-image ${showFullGallery ? 'animate-image' : ''}`}
                      style={{
                        left: calculateImagePosition(imageIndex),
                        '--initialX': initialX,
                        '--initialY': initialY,
                        '--initialAngle': initialAngle,
                        animationDelay: `${globalImageIndex * 0.1}s`,
                      }}
                      onClick={() => openImageCarousel(globalImageIndex)}
                      loading="lazy"
                    />
                  </React.Fragment>
                );
              })}
              <img src={filme} alt="Fundo do filme" className="filme-preto" loading="lazy" />
            </div>
          ))}
        </div>
      )}

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>×</button>

            {/* Navigation buttons fixed on the modal background */}
            <button
              className="modal-nav-button left"
              onClick={(e) => {
                e.stopPropagation();
                previousImage();
              }}
            >
              {"<"}
            </button>

            {/* Container for the image and title */}
            <div className="modal-image-title-container">
              {/* Image Container */}
              <div className="modal-image-container">
                <img
                  src={selectedImage}
                  alt="Imagem ampliada"
                  onLoad={handleImageLoad}
                  className="modal-image"
                />
              </div>

              {/* Author title next to the image */}
              <div className="modal-title-container">
                <h3 className="author-title">{titles[selectedImageIndex]}</h3>
              </div>
            </div>

            <button
              className="modal-nav-button right"
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
            >
              {">"}
            </button>
          </div>
        </div>
      )}

      {/* Condicional para exibir o botão de voltar */}
      {showBackButton && (
        <button onClick={onBack} className="back-button">
          Voltar
        </button>
      )}
    </div>
  );
};

export default Edition;
