import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Admin/Login/Login";
import AdminHomePage from "./Pages/Admin/AdminHomePage/AdminHomePage";
import TextEditor from "./Pages/Admin/TextEditor/TextEditor";
import ManageEditions from "./Pages/Admin/EditionEditor/ManageEditions";
import Register from "./Pages/Admin/Register/Register";
import ManageUsers from "./Pages/Admin/ManageUsers/ManageUsers";
import Eye from "./Components/Eye/Eye";
import IntroVideo from "./Components/IntroVideo/IntroVideo";
import Recovery from "./Pages/Recovery/Recovery";
import EditEdition from "./Pages/Admin/EditEdition/EditEdition";


const App = () => {
  const [showIntro, setShowIntro] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleVideoEnd = () => {
    setShowIntro(false);
    navigate("/"); // Navegar para home após o vídeo
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setShowIntro(false);
    }
  }, [location]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/eye" element={<Eye />} />

        {/* Rotas de admin */}
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/home" element={<AdminHomePage />} />
        <Route path="/admin/editor" element={<TextEditor />} />
        <Route path="/admin/manage-editions" element={<ManageEditions />} />
        <Route path="/admin/register" element={<Register />} />
        <Route path="/admin/users" element={<ManageUsers />} />
        <Route path="/admin/edit-edition/:id" element={<EditEdition />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
