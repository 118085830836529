import './Subnavs.scss';
import React from 'react';

function Sub3(props) {

    let estilo = {
        left: '-45vw', transition: 'all 1s'
    };
    (props.subOn === "show-3" ? estilo = { left: '5vw', transition: 'all 1s' } : estilo = { left: '-45vw', transition: 'all 1s' })

    function setPlace(position) {
        props.scrollToSection('ter', 'pagina-vertical-3', position);
        setTimeout(function () {
            props.setSecondaryIconColor("var(--azul)");
        }.bind(this), 2300)
    }

    return (
        <div>
            {props.placeOn !== 'pagina-vertical-3' && (
                <div className="dropdown drop-3" style={estilo} onMouseEnter={() => props.showSub("show-3")} onMouseLeave={() => props.showSub("show-0")}>
                    <div className={"sub sub-roxo a" + props.bg.substring(6, props.bg.length - 1)}>
                        <a href="#fortalecendo" onClick={() => setPlace('fortalecendo')}><span> &gt; Galera que quer chegar fortalecendo no amor</span></a>
                        <a href="#apoio" onClick={() => setPlace('apoio')}><span> &gt; Galera que quer apoiar com algum serviço ou produto</span></a>
                        <a href="#big-companies" onClick={() => setPlace('big-companies')}><span> &gt; Big companies</span></a>
                        <a href="#me-liga" onClick={() => setPlace('me-liga')}><span> &gt; Me liga, me manda um telegrama...</span></a>
                    </div>
                </div>
            )}
        </div>
    );

}

export default Sub3;
