const images = {
  // Edição 1
  edit1_1_small: require("./edicao1 (1).png"),
  edit1_1_large: require("./edicao1_ampliada (1).png"),
  edit1_2_small: require("./edicao1 (2).png"),
  edit1_2_large: require("./edicao1_ampliada (2).png"),
  edit1_3_small: require("./edicao1 (3).png"),
  edit1_3_large: require("./edicao1_ampliada (3).png"),
  edit1_4_small: require("./edicao1 (4).png"),
  edit1_4_large: require("./edicao1_ampliada (4).png"),
  edit1_5_small: require("./edicao1 (5).png"),
  edit1_5_large: require("./edicao1_ampliada (5).png"),
  edit1_6_small: require("./edicao1 (6).png"),
  edit1_6_large: require("./edicao1_ampliada (6).png"),
  edit1_7_small: require("./edicao1 (7).png"),
  edit1_7_large: require("./edicao1_ampliada (7).png"),
  edit1_8_small: require("./edicao1 (8).png"),
  edit1_8_large: require("./edicao1_ampliada (8).png"),
  edit1_9_small: require("./edicao1 (9).png"),
  edit1_9_large: require("./edicao1_ampliada (9).png"),
  edit1_10_small: require("./edicao1 (10).png"),
  edit1_10_large: require("./edicao1_ampliada (10).png"),
  edit1_11_small: require("./edicao1 (11).png"),
  edit1_11_large: require("./edicao1_ampliada (11).png"),
  edit1_12_small: require("./edicao1 (12).png"),
  edit1_12_large: require("./edicao1_ampliada (12).png"),
  edit1_13_small: require("./edicao1 (13).png"),
  edit1_13_large: require("./edicao1_ampliada (13).png"),
  edit1_14_small: require("./edicao1 (14).png"),
  edit1_14_large: require("./edicao1_ampliada (14).png"),
  edit1_15_small: require("./edicao1 (15).png"),
  edit1_15_large: require("./edicao1_ampliada (15).png"),
  edit1_16_small: require("./edicao1 (16).png"),
  edit1_16_large: require("./edicao1_ampliada (16).png"),
  edit1_17_small: require("./edicao1 (17).png"),
  edit1_17_large: require("./edicao1_ampliada (17).png"),
  edit1_18_small: require("./edicao1 (18).png"),
  edit1_18_large: require("./edicao1_ampliada (18).png"),
  edit1_19_small: require("./edicao1 (19).png"),
  edit1_19_large: require("./edicao1_ampliada (19).png"),
  edit1_20_small: require("./edicao1 (20).png"),
  edit1_20_large: require("./edicao1_ampliada (20).png"),
  edit1_21_small: require("./edicao1 (21).png"),
  edit1_21_large: require("./edicao1_ampliada (21).png"),
  edit1_22_small: require("./edicao1 (22).png"),
  edit1_22_large: require("./edicao1_ampliada (22).png"),
  edit1_23_small: require("./edicao1 (23).png"),
  edit1_23_large: require("./edicao1_ampliada (23).png"),
  edit1_24_small: require("./edicao1 (24).png"),
  edit1_24_large: require("./edicao1_ampliada (24).png"),
  edit1_25_small: require("./edicao1 (25).png"),
  edit1_25_large: require("./edicao1_ampliada (25).png"),
  edit1_26_small: require("./edicao1 (26).png"),
  edit1_26_large: require("./edicao1_ampliada (26).png"),
  edit1_27_small: require("./edicao1 (27).png"),
  edit1_27_large: require("./edicao1_ampliada (27).png"),
  edit1_28_small: require("./edicao1 (28).png"),
  edit1_28_large: require("./edicao1_ampliada (28).png"),
  edit1_29_small: require("./edicao1 (29).png"),
  edit1_29_large: require("./edicao1_ampliada (29).png"),
  edit1_30_small: require("./edicao1 (30).png"),
  edit1_30_large: require("./edicao1_ampliada (30).png"),
  edit1_31_small: require("./edicao1 (31).png"),
  edit1_31_large: require("./edicao1_ampliada (31).png"),
  edit1_32_small: require("./edicao1 (32).png"),
  edit1_32_large: require("./edicao1_ampliada (32).png"),
  edit1_33_small: require("./edicao1 (33).png"),
  edit1_33_large: require("./edicao1_ampliada (33).png"),
  edit1_34_small: require("./edicao1 (34).png"),
  edit1_34_large: require("./edicao1_ampliada (34).png"),
  edit1_35_small: require("./edicao1 (35).png"),
  edit1_35_large: require("./edicao1_ampliada (35).png"),
  edit1_36_small: require("./edicao1 (36).png"),
  edit1_36_large: require("./edicao1_ampliada (36).png"),
  edit1_37_small: require("./edicao1 (37).png"),
  edit1_37_large: require("./edicao1_ampliada (37).png"),
  edit1_38_small: require("./edicao1 (38).png"),
  edit1_38_large: require("./edicao1_ampliada (38).png"),
  edit1_39_small: require("./edicao1 (39).png"),
  edit1_39_large: require("./edicao1_ampliada (39).png"),

  // Edição 2
  edit2_1_small: require("./edicao2 (1).png"),
  edit2_1_large: require("./edicao2_ampliada (1).png"),
  edit2_2_small: require("./edicao2 (2).png"),
  edit2_2_large: require("./edicao2_ampliada (2).png"),
  edit2_3_small: require("./edicao2 (3).png"),
  edit2_3_large: require("./edicao2_ampliada (3).png"),
  edit2_4_small: require("./edicao2 (4).png"),
  edit2_4_large: require("./edicao2_ampliada (4).png"),
  edit2_5_small: require("./edicao2 (5).png"),
  edit2_5_large: require("./edicao2_ampliada (5).png"),
  edit2_6_small: require("./edicao2 (6).png"),
  edit2_6_large: require("./edicao2_ampliada (6).png"),
  edit2_7_small: require("./edicao2 (7).png"),
  edit2_7_large: require("./edicao2_ampliada (7).png"),
  edit2_8_small: require("./edicao2 (8).png"),
  edit2_8_large: require("./edicao2_ampliada (8).png"),
  edit2_9_small: require("./edicao2 (9).png"),
  edit2_9_large: require("./edicao2_ampliada (9).png"),
  edit2_10_small: require("./edicao2 (10).png"),
  edit2_10_large: require("./edicao2_ampliada (10).png"),
  edit2_11_small: require("./edicao2 (11).png"),
  edit2_11_large: require("./edicao2_ampliada (11).png"),
  edit2_12_small: require("./edicao2 (12).png"),
  edit2_12_large: require("./edicao2_ampliada (12).png"),
  edit2_13_small: require("./edicao2 (13).png"),
  edit2_13_large: require("./edicao2_ampliada (13).png"),
  edit2_14_small: require("./edicao2 (14).png"),
  edit2_14_large: require("./edicao2_ampliada (14).png"),
  edit2_15_small: require("./edicao2 (15).png"),
  edit2_15_large: require("./edicao2_ampliada (15).png"),
  edit2_16_small: require("./edicao2 (16).png"),
  edit2_16_large: require("./edicao2_ampliada (16).png"),
  edit2_17_small: require("./edicao2 (17).png"),
  edit2_17_large: require("./edicao2_ampliada (17).png"),
  edit2_18_small: require("./edicao2 (18).png"),
  edit2_18_large: require("./edicao2_ampliada (18).png"),
  edit2_19_small: require("./edicao2 (19).png"),
  edit2_19_large: require("./edicao2_ampliada (19).png"),
  edit2_20_small: require("./edicao2 (20).png"),
  edit2_20_large: require("./edicao2_ampliada (20).png"),
  edit2_21_small: require("./edicao2 (21).png"),
  edit2_21_large: require("./edicao2_ampliada (21).png"),
  edit2_22_small: require("./edicao2 (22).png"),
  edit2_22_large: require("./edicao2_ampliada (22).png"),
  edit2_23_small: require("./edicao2 (23).png"),
  edit2_23_large: require("./edicao2_ampliada (23).png"),
  edit2_24_small: require("./edicao2 (24).png"),
  edit2_24_large: require("./edicao2_ampliada (24).png"),
  edit2_25_small: require("./edicao2 (25).png"),
  edit2_25_large: require("./edicao2_ampliada (25).png"),
  edit2_26_small: require("./edicao2 (26).png"),
  edit2_26_large: require("./edicao2_ampliada (26).png"),
  edit2_27_small: require("./edicao2 (27).png"),
  edit2_27_large: require("./edicao2_ampliada (27).png"),
  edit2_28_small: require("./edicao2 (28).png"),
  edit2_28_large: require("./edicao2_ampliada (28).png"),
  edit2_29_small: require("./edicao2 (29).png"),
  edit2_29_large: require("./edicao2_ampliada (29).png"),
  edit2_30_small: require("./edicao2 (30).png"),
  edit2_30_large: require("./edicao2_ampliada (30).png"),
  edit2_31_small: require("./edicao2 (31).png"),
  edit2_31_large: require("./edicao2_ampliada (31).png"),
  edit2_32_small: require("./edicao2 (32).png"),
  edit2_32_large: require("./edicao2_ampliada (32).png"),
  edit2_33_small: require("./edicao2 (33).png"),
  edit2_33_large: require("./edicao2_ampliada (33).png"),
  edit2_34_small: require("./edicao2 (34).png"),
  edit2_34_large: require("./edicao2_ampliada (34).png"),
  edit2_35_small: require("./edicao2 (35).png"),
  edit2_35_large: require("./edicao2_ampliada (35).png"),
  edit2_36_small: require("./edicao2 (36).png"),
  edit2_36_large: require("./edicao2_ampliada (36).png"),
  edit2_37_small: require("./edicao2 (37).png"),
  edit2_37_large: require("./edicao2_ampliada (37).png"),
  edit2_38_small: require("./edicao2 (38).png"),
  edit2_38_large: require("./edicao2_ampliada (38).png"),
  edit2_39_small: require("./edicao2 (39).png"),
  edit2_39_large: require("./edicao2_ampliada (39).png"),

  // Edição 3
  edit3_1_small: require("./edicao3 (1).png"),
  edit3_1_large: require("./edicao3_ampliada (1).png"),
  edit3_2_small: require("./edicao3 (2).png"),
  edit3_2_large: require("./edicao3_ampliada (2).png"),
  edit3_3_small: require("./edicao3 (3).png"),
  edit3_3_large: require("./edicao3_ampliada (3).png"),
  edit3_4_small: require("./edicao3 (4).png"),
  edit3_4_large: require("./edicao3_ampliada (4).png"),
  edit3_5_small: require("./edicao3 (5).png"),
  edit3_5_large: require("./edicao3_ampliada (5).png"),
  edit3_6_small: require("./edicao3 (6).png"),
  edit3_6_large: require("./edicao3_ampliada (6).png"),
  edit3_7_small: require("./edicao3 (7).png"),
  edit3_7_large: require("./edicao3_ampliada (7).png"),
  edit3_8_small: require("./edicao3 (8).png"),
  edit3_8_large: require("./edicao3_ampliada (8).png"),
  edit3_9_small: require("./edicao3 (9).png"),
  edit3_9_large: require("./edicao3_ampliada (9).png"),
  edit3_10_small: require("./edicao3 (10).png"),
  edit3_10_large: require("./edicao3_ampliada (10).png"),
  edit3_11_small: require("./edicao3 (11).png"),
  edit3_11_large: require("./edicao3_ampliada (11).png"),
  edit3_12_small: require("./edicao3 (12).png"),
  edit3_12_large: require("./edicao3_ampliada (12).png"),
  edit3_13_small: require("./edicao3 (13).png"),
  edit3_13_large: require("./edicao3_ampliada (13).png"),
  edit3_14_small: require("./edicao3 (14).png"),
  edit3_14_large: require("./edicao3_ampliada (14).png"),
  edit3_15_small: require("./edicao3 (15).png"),
  edit3_15_large: require("./edicao3_ampliada (15).png"),
  edit3_16_small: require("./edicao3 (16).png"),
  edit3_16_large: require("./edicao3_ampliada (16).png"),
  edit3_17_small: require("./edicao3 (17).png"),
  edit3_17_large: require("./edicao3_ampliada (17).png"),
  edit3_18_small: require("./edicao3 (18).png"),
  edit3_18_large: require("./edicao3_ampliada (18).png"),
  edit3_19_small: require("./edicao3 (19).png"),
  edit3_19_large: require("./edicao3_ampliada (19).png"),
  edit3_20_small: require("./edicao3 (20).png"),
  edit3_20_large: require("./edicao3_ampliada (20).png"),
  edit3_21_small: require("./edicao3 (21).png"),
  edit3_21_large: require("./edicao3_ampliada (21).png"),
  edit3_22_small: require("./edicao3 (22).png"),
  edit3_22_large: require("./edicao3_ampliada (22).png"),
  edit3_23_small: require("./edicao3 (23).png"),
  edit3_23_large: require("./edicao3_ampliada (23).png"),
  edit3_24_small: require("./edicao3 (24).png"),
  edit3_24_large: require("./edicao3_ampliada (24).png"),
  edit3_25_small: require("./edicao3 (25).png"),
  edit3_25_large: require("./edicao3_ampliada (25).png"),
  edit3_26_small: require("./edicao3 (26).png"),
  edit3_26_large: require("./edicao3_ampliada (26).png"),
  edit3_27_small: require("./edicao3 (27).png"),
  edit3_27_large: require("./edicao3_ampliada (27).png"),
  edit3_28_small: require("./edicao3 (28).png"),
  edit3_28_large: require("./edicao3_ampliada (28).png"),
  edit3_29_small: require("./edicao3 (29).png"),
  edit3_29_large: require("./edicao3_ampliada (29).png"),
  edit3_30_small: require("./edicao3 (30).png"),
  edit3_30_large: require("./edicao3_ampliada (30).png"),
  edit3_31_small: require("./edicao3 (31).png"),
  edit3_31_large: require("./edicao3_ampliada (31).png"),
  edit3_32_small: require("./edicao3 (32).png"),
  edit3_32_large: require("./edicao3_ampliada (32).png"),
  edit3_33_small: require("./edicao3 (33).png"),
  edit3_33_large: require("./edicao3_ampliada (33).png"),
  edit3_34_small: require("./edicao3 (34).png"),
  edit3_34_large: require("./edicao3_ampliada (34).png"),
  edit3_35_small: require("./edicao3 (35).png"),
  edit3_35_large: require("./edicao3_ampliada (35).png"),
  edit3_36_small: require("./edicao3 (36).png"),
  edit3_36_large: require("./edicao3_ampliada (36).png"),
  edit3_37_small: require("./edicao3 (37).png"),
  edit3_37_large: require("./edicao3_ampliada (37).png"),
  edit3_38_small: require("./edicao3 (38).png"),
  edit3_38_large: require("./edicao3_ampliada (38).png"),
  edit3_39_small: require("./edicao3 (39).png"),
  edit3_39_large: require("./edicao3_ampliada (39).png"),

  // Edição 4
  edit4_1_small: require("./edicao4 (1).png"),
  edit4_1_large: require("./edicao4_ampliada (1).png"),
  edit4_2_small: require("./edicao4 (2).png"),
  edit4_2_large: require("./edicao4_ampliada (2).png"),
  edit4_3_small: require("./edicao4 (3).png"),
  edit4_3_large: require("./edicao4_ampliada (3).png"),
  edit4_4_small: require("./edicao4 (4).png"),
  edit4_4_large: require("./edicao4_ampliada (4).png"),
  edit4_5_small: require("./edicao4 (5).png"),
  edit4_5_large: require("./edicao4_ampliada (5).png"),
  edit4_6_small: require("./edicao4 (6).png"),
  edit4_6_large: require("./edicao4_ampliada (6).png"),
  edit4_7_small: require("./edicao4 (7).png"),
  edit4_7_large: require("./edicao4_ampliada (7).png"),
  edit4_8_small: require("./edicao4 (8).png"),
  edit4_8_large: require("./edicao4_ampliada (8).png"),
  edit4_9_small: require("./edicao4 (9).png"),
  edit4_9_large: require("./edicao4_ampliada (9).png"),
  edit4_10_small: require("./edicao4 (10).png"),
  edit4_10_large: require("./edicao4_ampliada (10).png"),
  edit4_11_small: require("./edicao4 (11).png"),
  edit4_11_large: require("./edicao4_ampliada (11).png"),
  edit4_12_small: require("./edicao4 (12).png"),
  edit4_12_large: require("./edicao4_ampliada (12).png"),
  edit4_13_small: require("./edicao4 (13).png"),
  edit4_13_large: require("./edicao4_ampliada (13).png"),
  edit4_14_small: require("./edicao4 (14).png"),
  edit4_14_large: require("./edicao4_ampliada (14).png"),
  edit4_15_small: require("./edicao4 (15).png"),
  edit4_15_large: require("./edicao4_ampliada (15).png"),
  edit4_16_small: require("./edicao4 (16).png"),
  edit4_16_large: require("./edicao4_ampliada (16).png"),
  edit4_17_small: require("./edicao4 (17).png"),
  edit4_17_large: require("./edicao4_ampliada (17).png"),
  edit4_18_small: require("./edicao4 (18).png"),
  edit4_18_large: require("./edicao4_ampliada (18).png"),
  edit4_19_small: require("./edicao4 (19).png"),
  edit4_19_large: require("./edicao4_ampliada (19).png"),
  edit4_20_small: require("./edicao4 (20).png"),
  edit4_20_large: require("./edicao4_ampliada (20).png"),

  // Edição 5
  edit5_1_small: require("./edicao5 (1).png"),
  edit5_1_large: require("./edicao5_ampliada (1).png"),
  edit5_2_small: require("./edicao5 (2).png"),
  edit5_2_large: require("./edicao5_ampliada (2).png"),
  edit5_3_small: require("./edicao5 (3).png"),
  edit5_3_large: require("./edicao5_ampliada (3).png"),
  edit5_4_small: require("./edicao5 (4).png"),
  edit5_4_large: require("./edicao5_ampliada (4).png"),
  edit5_5_small: require("./edicao5 (5).png"),
  edit5_5_large: require("./edicao5_ampliada (5).png"),
  edit5_6_small: require("./edicao5 (6).png"),
  edit5_6_large: require("./edicao5_ampliada (6).png"),
  edit5_7_small: require("./edicao5 (7).png"),
  edit5_7_large: require("./edicao5_ampliada (7).png"),
  edit5_8_small: require("./edicao5 (8).png"),
  edit5_8_large: require("./edicao5_ampliada (8).png"),
  edit5_9_small: require("./edicao5 (9).png"),
  edit5_9_large: require("./edicao5_ampliada (9).png"),
  edit5_10_small: require("./edicao5 (10).png"),
  edit5_10_large: require("./edicao5_ampliada (10).png"),
  edit5_11_small: require("./edicao5 (11).png"),
  edit5_11_large: require("./edicao5_ampliada (11).png"),
  edit5_12_small: require("./edicao5 (12).png"),
  edit5_12_large: require("./edicao5_ampliada (12).png"),
  edit5_13_small: require("./edicao5 (13).png"),
  edit5_13_large: require("./edicao5_ampliada (13).png"),
  edit5_14_small: require("./edicao5 (14).png"),
  edit5_14_large: require("./edicao5_ampliada (14).png"),
  edit5_15_small: require("./edicao5 (15).png"),
  edit5_15_large: require("./edicao5_ampliada (15).png"),
  edit5_16_small: require("./edicao5 (16).png"),
  edit5_16_large: require("./edicao5_ampliada (16).png"),
  edit5_17_small: require("./edicao5 (17).png"),
  edit5_17_large: require("./edicao5_ampliada (17).png"),
  edit5_18_small: require("./edicao5 (18).png"),
  edit5_18_large: require("./edicao5_ampliada (18).png"),
  edit5_19_small: require("./edicao5 (19).png"),
  edit5_19_large: require("./edicao5_ampliada (19).png"),
  edit5_20_small: require("./edicao5 (20).png"),
  edit5_20_large: require("./edicao5_ampliada (20).png"),

  // Edição 6
  edit6_1_small: require("./edicao6 (1).png"),
  edit6_1_large: require("./edicao6_ampliada (1).png"),
  edit6_2_small: require("./edicao6 (2).png"),
  edit6_2_large: require("./edicao6_ampliada (2).png"),
  edit6_3_small: require("./edicao6 (3).png"),
  edit6_3_large: require("./edicao6_ampliada (3).png"),
  edit6_4_small: require("./edicao6 (4).png"),
  edit6_4_large: require("./edicao6_ampliada (4).png"),
  edit6_5_small: require("./edicao6 (5).png"),
  edit6_5_large: require("./edicao6_ampliada (5).png"),
  edit6_6_small: require("./edicao6 (6).png"),
  edit6_6_large: require("./edicao6_ampliada (6).png"),
  edit6_7_small: require("./edicao6 (7).png"),
  edit6_7_large: require("./edicao6_ampliada (7).png"),
  edit6_8_small: require("./edicao6 (8).png"),
  edit6_8_large: require("./edicao6_ampliada (8).png"),
  edit6_9_small: require("./edicao6 (9).png"),
  edit6_9_large: require("./edicao6_ampliada (9).png"),
  edit6_10_small: require("./edicao6 (10).png"),
  edit6_10_large: require("./edicao6_ampliada (10).png"),
  edit6_11_small: require("./edicao6 (11).png"),
  edit6_11_large: require("./edicao6_ampliada (11).png"),
  edit6_12_small: require("./edicao6 (12).png"),
  edit6_12_large: require("./edicao6_ampliada (12).png"),
  edit6_13_small: require("./edicao6 (13).png"),
  edit6_13_large: require("./edicao6_ampliada (13).png"),
  edit6_14_small: require("./edicao6 (14).png"),
  edit6_14_large: require("./edicao6_ampliada (14).png"),
  edit6_15_small: require("./edicao6 (15).png"),
  edit6_15_large: require("./edicao6_ampliada (15).png"),
  edit6_16_small: require("./edicao6 (16).png"),
  edit6_16_large: require("./edicao6_ampliada (16).png"),
  edit6_17_small: require("./edicao6 (17).png"),
  edit6_17_large: require("./edicao6_ampliada (17).png"),
  edit6_18_small: require("./edicao6 (18).png"),
  edit6_18_large: require("./edicao6_ampliada (18).png"),
  edit6_19_small: require("./edicao6 (19).png"),
  edit6_19_large: require("./edicao6_ampliada (19).png"),

  // Edição 7
  edit7_1_small: require("./edicao7 (1).png"),
  edit7_1_large: require("./edicao7_ampliada (1).png"),
  edit7_2_small: require("./edicao7 (2).png"),
  edit7_2_large: require("./edicao7_ampliada (2).png"),
  edit7_3_small: require("./edicao7 (3).png"),
  edit7_3_large: require("./edicao7_ampliada (3).png"),
  edit7_4_small: require("./edicao7 (4).png"),
  edit7_4_large: require("./edicao7_ampliada (4).png"),
  edit7_5_small: require("./edicao7 (5).png"),
  edit7_5_large: require("./edicao7_ampliada (5).png"),
  edit7_6_small: require("./edicao7 (6).png"),
  edit7_6_large: require("./edicao7_ampliada (6).png"),
  edit7_7_small: require("./edicao7 (7).png"),
  edit7_7_large: require("./edicao7_ampliada (7).png"),
  edit7_8_small: require("./edicao7 (8).png"),
  edit7_8_large: require("./edicao7_ampliada (8).png"),
  edit7_9_small: require("./edicao7 (9).png"),
  edit7_9_large: require("./edicao7_ampliada (9).png"),
  edit7_10_small: require("./edicao7 (10).png"),
  edit7_10_large: require("./edicao7_ampliada (10).png"),
  edit7_11_small: require("./edicao7 (11).png"),
  edit7_11_large: require("./edicao7_ampliada (11).png"),
  edit7_12_small: require("./edicao7 (12).png"),
  edit7_12_large: require("./edicao7_ampliada (12).png"),
  edit7_13_small: require("./edicao7 (13).png"),
  edit7_13_large: require("./edicao7_ampliada (13).png"),
  edit7_14_small: require("./edicao7 (14).png"),
  edit7_14_large: require("./edicao7_ampliada (14).png"),
  edit7_15_small: require("./edicao7 (15).png"),
  edit7_15_large: require("./edicao7_ampliada (15).png"),
  edit7_16_small: require("./edicao7 (16).png"),
  edit7_16_large: require("./edicao7_ampliada (16).png"),
  edit7_17_small: require("./edicao7 (17).png"),
  edit7_17_large: require("./edicao7_ampliada (17).png"),
  edit7_18_small: require("./edicao7 (18).png"),
  edit7_18_large: require("./edicao7_ampliada (18).png"),
  edit7_19_small: require("./edicao7 (19).png"),
  edit7_19_large: require("./edicao7_ampliada (19).png"),
  edit7_20_small: require("./edicao7 (20).png"),
  edit7_20_large: require("./edicao7_ampliada (20).png"),
  edit7_21_small: require("./edicao7 (21).png"),
  edit7_21_large: require("./edicao7_ampliada (21).png"),
  edit7_22_small: require("./edicao7 (22).png"),
  edit7_22_large: require("./edicao7_ampliada (22).png"),
  edit7_23_small: require("./edicao7 (23).png"),
  edit7_23_large: require("./edicao7_ampliada (23).png"),
  edit7_24_small: require("./edicao7 (24).png"),
  edit7_24_large: require("./edicao7_ampliada (24).png"),
  edit7_25_small: require("./edicao7 (25).png"),
  edit7_25_large: require("./edicao7_ampliada (25).png"),
  edit7_26_small: require("./edicao7 (26).png"),
  edit7_26_large: require("./edicao7_ampliada (26).png"),
  edit7_27_small: require("./edicao7 (27).png"),
  edit7_27_large: require("./edicao7_ampliada (27).png"),
  edit7_28_small: require("./edicao7 (28).png"),
  edit7_28_large: require("./edicao7_ampliada (28).png"),
  edit7_29_small: require("./edicao7 (29).png"),
  edit7_29_large: require("./edicao7_ampliada (29).png"),
  edit7_30_small: require("./edicao7 (30).png"),
  edit7_30_large: require("./edicao7_ampliada (30).png"),
  edit7_31_small: require("./edicao7 (31).png"),
  edit7_31_large: require("./edicao7_ampliada (31).png"),
  edit7_32_small: require("./edicao7 (32).png"),
  edit7_32_large: require("./edicao7_ampliada (32).png"),
 
  // Edição 8
  edit8_1_small: require("./edicao8 (1).png"),
  edit8_1_large: require("./edicao8_ampliada (1).png"),
  edit8_2_small: require("./edicao8 (2).png"),
  edit8_2_large: require("./edicao8_ampliada (2).png"),
  edit8_3_small: require("./edicao8 (3).png"),
  edit8_3_large: require("./edicao8_ampliada (3).png"),
  edit8_4_small: require("./edicao8 (4).png"),
  edit8_4_large: require("./edicao8_ampliada (4).png"),
  edit8_5_small: require("./edicao8 (5).png"),
  edit8_5_large: require("./edicao8_ampliada (5).png"),
  edit8_6_small: require("./edicao8 (6).png"),
  edit8_6_large: require("./edicao8_ampliada (6).png"),
  edit8_7_small: require("./edicao8 (7).png"),
  edit8_7_large: require("./edicao8_ampliada (7).png"),
  edit8_8_small: require("./edicao8 (8).png"),
  edit8_8_large: require("./edicao8_ampliada (8).png"),
  edit8_9_small: require("./edicao8 (9).png"),
  edit8_9_large: require("./edicao8_ampliada (9).png"),
  edit8_10_small: require("./edicao8 (10).png"),
  edit8_10_large: require("./edicao8_ampliada (10).png"),
  edit8_11_small: require("./edicao8 (11).png"),
  edit8_11_large: require("./edicao8_ampliada (11).png"),
  edit8_12_small: require("./edicao8 (12).png"),
  edit8_12_large: require("./edicao8_ampliada (12).png"),
  edit8_13_small: require("./edicao8 (13).png"),
  edit8_13_large: require("./edicao8_ampliada (13).png"),
  edit8_14_small: require("./edicao8 (14).png"),
  edit8_14_large: require("./edicao8_ampliada (14).png"),
  edit8_15_small: require("./edicao8 (15).png"),
  edit8_15_large: require("./edicao8_ampliada (15).png"),
  edit8_16_small: require("./edicao8 (16).png"),
  edit8_16_large: require("./edicao8_ampliada (16).png"),
  edit8_17_small: require("./edicao8 (17).png"),
  edit8_17_large: require("./edicao8_ampliada (17).png"),
  edit8_18_small: require("./edicao8 (18).png"),
  edit8_18_large: require("./edicao8_ampliada (18).png"),
  edit8_19_small: require("./edicao8 (19).png"),
  edit8_19_large: require("./edicao8_ampliada (19).png"),
  edit8_20_small: require("./edicao8 (20).png"),
  edit8_20_large: require("./edicao8_ampliada (20).png"),
  edit8_21_small: require("./edicao8 (21).png"),
  edit8_21_large: require("./edicao8_ampliada (21).png"),
  edit8_22_small: require("./edicao8 (22).png"),
  edit8_22_large: require("./edicao8_ampliada (22).png"),
  edit8_23_small: require("./edicao8 (23).png"),
  edit8_23_large: require("./edicao8_ampliada (23).png"),
  edit8_24_small: require("./edicao8 (24).png"),
  edit8_24_large: require("./edicao8_ampliada (24).png"),
  edit8_25_small: require("./edicao8 (25).png"),
  edit8_25_large: require("./edicao8_ampliada (25).png"),
  edit8_26_small: require("./edicao8 (26).png"),
  edit8_26_large: require("./edicao8_ampliada (26).png"),
  edit8_27_small: require("./edicao8 (27).png"),
  edit8_27_large: require("./edicao8_ampliada (27).png"),
  edit8_28_small: require("./edicao8 (28).png"),
  edit8_28_large: require("./edicao8_ampliada (28).png"),
  edit8_29_small: require("./edicao8 (29).png"),
  edit8_29_large: require("./edicao8_ampliada (29).png"),
  edit8_30_small: require("./edicao8 (30).png"),
  edit8_30_large: require("./edicao8_ampliada (30).png"),
  edit8_31_small: require("./edicao8 (31).png"),
  edit8_31_large: require("./edicao8_ampliada (31).png"),
  edit8_32_small: require("./edicao8 (32).png"),
  edit8_32_large: require("./edicao8_ampliada (32).png"),
  edit8_33_small: require("./edicao8 (33).png"),
  edit8_33_large: require("./edicao8_ampliada (33).png"),
  edit8_34_small: require("./edicao8 (34).png"),
  edit8_34_large: require("./edicao8_ampliada (34).png"),
  edit8_35_small: require("./edicao8 (35).png"),
  edit8_35_large: require("./edicao8_ampliada (35).png"),
  edit8_36_small: require("./edicao8 (36).png"),
  edit8_36_large: require("./edicao8_ampliada (36).png"),
  edit8_37_small: require("./edicao8 (37).png"),
  edit8_37_large: require("./edicao8_ampliada (37).png"),
  edit8_38_small: require("./edicao8 (38).png"),
  edit8_38_large: require("./edicao8_ampliada (38).png"),
  edit8_39_small: require("./edicao8 (39).png"),
  edit8_39_large: require("./edicao8_ampliada (39).png"),
  edit8_40_small: require("./edicao8 (40).png"),
  edit8_40_large: require("./edicao8_ampliada (40).png"),
  edit8_41_small: require("./edicao8 (41).png"),
  edit8_41_large: require("./edicao8_ampliada (41).png"),
  edit8_42_small: require("./edicao8 (42).png"),
  edit8_42_large: require("./edicao8_ampliada (42).png"),
  edit8_43_small: require("./edicao8 (43).png"),
  edit8_43_large: require("./edicao8_ampliada (43).png"),
  edit8_44_small: require("./edicao8 (44).png"),
  edit8_44_large: require("./edicao8_ampliada (44).png"),
  edit8_45_small: require("./edicao8 (45).png"),
  edit8_45_large: require("./edicao8_ampliada (45).png"),
  edit8_46_small: require("./edicao8 (46).png"),
  edit8_46_large: require("./edicao8_ampliada (46).png"),
  edit8_47_small: require("./edicao8 (47).png"),
  edit8_47_large: require("./edicao8_ampliada (47).png"),
  edit8_48_small: require("./edicao8 (48).png"),
  edit8_48_large: require("./edicao8_ampliada (48).png"),
  edit8_49_small: require("./edicao8 (49).png"),
  edit8_49_large: require("./edicao8_ampliada (49).png"),
  edit8_50_small: require("./edicao8 (50).png"),
  edit8_50_large: require("./edicao8_ampliada (50).png"),
  edit8_51_small: require("./edicao8 (51).png"),
  edit8_51_large: require("./edicao8_ampliada (51).png"),
  edit8_52_small: require("./edicao8 (52).png"),
  edit8_52_large: require("./edicao8_ampliada (52).png"),
  edit8_53_small: require("./edicao8 (53).png"),
  edit8_53_large: require("./edicao8_ampliada (53).png"),
  edit8_54_small: require("./edicao8 (54).png"),
  edit8_54_large: require("./edicao8_ampliada (54).png"),
  edit8_55_small: require("./edicao8 (55).png"),
  edit8_55_large: require("./edicao8_ampliada (55).png"),
  edit8_56_small: require("./edicao8 (56).png"),
  edit8_56_large: require("./edicao8_ampliada (56).png"),
  edit8_57_small: require("./edicao8 (57).png"),
  edit8_57_large: require("./edicao8_ampliada (57).png"),
  edit8_58_small: require("./edicao8 (58).png"),
  edit8_58_large: require("./edicao8_ampliada (58).png"),
  edit8_59_small: require("./edicao8 (59).png"),
  edit8_59_large: require("./edicao8_ampliada (59).png"),
  edit8_60_small: require("./edicao8 (60).png"),
  edit8_60_large: require("./edicao8_ampliada (60).png"),
  edit8_61_small: require("./edicao8 (61).png"),
  edit8_61_large: require("./edicao8_ampliada (61).png"),
  edit8_62_small: require("./edicao8 (62).png"),
  edit8_62_large: require("./edicao8_ampliada (62).png"),
  edit8_63_small: require("./edicao8 (63).png"),
  edit8_63_large: require("./edicao8_ampliada (63).png"),
  edit8_64_small: require("./edicao8 (64).png"),
  edit8_64_large: require("./edicao8_ampliada (64).png"),
  edit8_65_small: require("./edicao8 (65).png"),
  edit8_65_large: require("./edicao8_ampliada (65).png"),
  edit8_66_small: require("./edicao8 (66).png"),
  edit8_66_large: require("./edicao8_ampliada (66).png"),
  edit8_67_small: require("./edicao8 (67).png"),
  edit8_67_large: require("./edicao8_ampliada (67).png"),
  edit8_68_small: require("./edicao8 (68).png"),
  edit8_68_large: require("./edicao8_ampliada (68).png"),
  edit8_69_small: require("./edicao8 (69).png"),
  edit8_69_large: require("./edicao8_ampliada (69).png"),
  edit8_70_small: require("./edicao8 (70).png"),
  edit8_70_large: require("./edicao8_ampliada (70).png"),
  edit8_71_small: require("./edicao8 (71).png"),
  edit8_71_large: require("./edicao8_ampliada (71).png"),
  edit8_72_small: require("./edicao8 (72).png"),
  edit8_72_large: require("./edicao8_ampliada (72).png"),
  edit8_73_small: require("./edicao8 (73).png"),
  edit8_73_large: require("./edicao8_ampliada (73).png"),
  edit8_74_small: require("./edicao8 (74).png"),
  edit8_74_large: require("./edicao8_ampliada (74).png"),
  edit8_75_small: require("./edicao8 (75).png"),
  edit8_75_large: require("./edicao8_ampliada (75).png"),
  edit8_76_small: require("./edicao8 (76).png"),
  edit8_76_large: require("./edicao8_ampliada (76).png"),
  edit8_77_small: require("./edicao8 (77).png"),
  edit8_77_large: require("./edicao8_ampliada (77).png"),
  edit8_78_small: require("./edicao8 (78).png"),
  edit8_78_large: require("./edicao8_ampliada (78).png"),

  capa1: require("./ediçao1.png"),
  capa2: require("./ediçao2.png"),
  capa3: require("./ediçao3.png"),
  capa4: require("./ediçao4.png"),
  capa5: require("./ediçao5.png"),
  capa6: require("./ediçao6.png"),
  capa7: require("./ediçao7.png"),
  capa8: require("./ediçao8.png"),
};

export default images;
