// src/Components/IntroVideo/IntroVideo.jsx
import React, { useEffect } from 'react';

const IntroVideo = ({ onVideoEnd }) => {
  useEffect(() => {
    const videoElement = document.getElementById('intro-video');

    const handleVideoEnd = () => {
      onVideoEnd();
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
      videoElement.play().catch(error => console.error("Error playing video:", error)); // Garantir que o vídeo comece a reproduzir

      return () => {
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, [onVideoEnd]);

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 1000, backgroundColor: 'black' }}>
      <video
        id="intro-video"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        autoPlay
        muted
      >
        <source src={process.env.PUBLIC_URL + '/assets/teaser.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default IntroVideo;

