import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

export default function EditUserModal({ user, open, onClose, onSave }) {
  const [username, setUsername] = useState(user.username);
  const [role, setRole] = useState(user.role.id);

  const handleSave = () => {
    onSave(username, role);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Usuário</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nome de Usuário"
          type="text"
          fullWidth
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Cargo</InputLabel>
          <Select
            value={role}
            label="Cargo"
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value="2">Moderador</MenuItem>
            <MenuItem value="3">Usuário Comum</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}
