// AdminHomePage.js
import React from "react";
import "./AdminHomePage.scss";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";

const AdminHomePage = () => {
  return (
    <div className="admin-home-page">
      <DashboardHeader />
      <div className="content">
        <h1>Bem-vindo ao Painel de Gerenciamento</h1>
        <p>
          Esta é a sua central de controle. Aqui, você pode gerenciar conteúdos,
          usuários, futuramente configurações. Utilize a barra de navegação para
          acessar as diferentes seções do painel e começar a gerenciar o site.
        </p>
      </div>
    </div>
  );
};

export default AdminHomePage;
