// Dashboard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./DashboardHeader.scss";
import logo from "../../Assets/logo-ecofoto.png";

export default function DashboardHeader() {
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("token");
    navigate("/admin");
  };

  return (
    <div className="dashboard-container">
      <img
        src={logo}
        alt="Logo"
        className="dashboard-logo"
        onClick={() => navigate("/admin/home")}
      />
      <div className="navigation-buttons">
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/home")}
        >
          Home
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/manage-covers")}
        >
          Editar Capa
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/editor")}
        >
          Editar Textos
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/manage-editions")}
        >
          Edições
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/register")}
        >
          Adicionar Moderadores
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate("/admin/users")}
        >
          Gerenciar Usuários
        </button>
      </div>
      <button className="dashboard-button logout-button" onClick={logout}>
        Sair
      </button>
    </div>
  );
}
