import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import service from "../../../service";
import { useNavigate } from "react-router-dom";
import "./ManageUsers.scss";

// Componentes
import EditUserModal from "../../../Components/EditUserModal/EditUserModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ConfirmDialog from "../../../Components/ConfirmDialog/ConfirmDialog";

// Ícones
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

export default function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [editingUser, setEditingUser] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  const verifyTokenAndRedirect = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/admin");
      return false;
    }
    return true;
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const openSnackbar = (severity, message) => {
    setSnackbar({ open: true, severity, message });
  };

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        navigate("/admin");
      }
      const usersData = await service.getAllUsers(token);
      setUsers(usersData.data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleEditUser = (user) => {
    setEditingUser(user);
  };

  const handleSaveEdit = async (username, roleId) => {
    if (!verifyTokenAndRedirect()) return;

    try {
      const res = await service.updateUser(
        editingUser.id,
        { username, roleId },
        sessionStorage.getItem("token")
      );
      if (res.status !== 200) {
        openSnackbar("error", res.data);
      } else {
        openSnackbar("success", "Usuário editado com sucesso!");
      }
      setEditingUser(null);
      fetchUsers();
    } catch (error) {
      console.error("Erro ao editar usuário:", error);
      openSnackbar("error", "Erro ao editar usuário!");
    }
  };
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = async (user) => {
    const token = sessionStorage.getItem("token");
    await service.deleteUser(user.id, token);
    openSnackbar("success", "Usuário deletado com sucesso!");
    fetchUsers();
  };
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (isLoading) {
    return <div>Carregando lista...</div>;
  }

  return (
    <div>
      <DashboardHeader />
      <div className="manage-users-container">
        <div className="manage-users-card">
          <h1>Gerenciar Usuários</h1>
          <input
            type="text"
            placeholder="Filtrar por nome de usuário"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <ul>
            {users
              .filter((user) =>
                user.username.toLowerCase().includes(filter.toLowerCase())
              )
              .map((user) => (
                <li key={user.username}>
                  {user.username}
                  <div className="role-display">
                    {user.role?.name || "Sem cargo"}
                  </div>
                  <div className="action-icons">
                    <IconButton
                      onClick={() => handleEditUser(user)}
                      aria-label="edit"
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteClick(user)}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div>
        {editingUser && (
          <EditUserModal
            user={editingUser}
            open={!!editingUser}
            onClose={() => setEditingUser(null)}
            onSave={handleSaveEdit}
          />
        )}
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          confirmDelete(userToDelete);
          setConfirmDialogOpen(false);
        }}
        message={`Você realmente quer deletar o usuário ${userToDelete?.username}?`}
      />
    </div>
  );
}
