import React, { useState } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./vertical.scss";
import "./search-page.scss";

function Pesquisa(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 6;

  const totalResults = props.result ? props.result.length : 0;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = props.result
    ? props.result.slice(indexOfFirstResult, indexOfLastResult)
    : [];

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const clickedResult = (e, color) => {
    props.scrollToSection(
      e.sectionId === 1 ? "primeiro" : e.sectionId === 2 ? "seg" : "ter",
      "pagina-vertical-" + e.sectionId
    );
    props.setPosition(e.tagId);
    setTimeout(() => {
      props.setSecondaryIconColor("var(--" + color + ")");
    }, 2300);
  };

  return (
    <div
      className={
        props.placeOn === "pesquisa"
          ? "vertical pesquisa pesquisa-up"
          : "vertical pesquisa down"
      }
    >
      <div className="vertical-right vertical-pesquisa">
        {props.result && (
          <h3 className="vertical-pesquisa-head">
            {props.result.length} resultados para "<u>{props.value}</u>"
          </h3>
        )}
        <div className="vertical-pesquisa-body">
          {currentResults.map((resultado, index) => {
            let novoTextinho = resultado.pureText;
            let indexText = novoTextinho
              .toLowerCase()
              .indexOf(props.value.toLowerCase());
            let trimmed = novoTextinho.substring(
              Math.max(0, indexText - 30),
              indexText + 80
            );
            trimmed = trimmed.substring(
              trimmed.indexOf(" "),
              trimmed.lastIndexOf(" ")
            );

            let color =
              resultado.sectionId === 1
                ? "verde"
                : resultado.sectionId === 2
                ? "rosa"
                : "azul";
            let secao =
              resultado.sectionId === 1
                ? "Nosso rolê"
                : resultado.sectionId === 2
                ? "Ciclo de Criação"
                : "Chega mais";

            return (
              <div
                key={index}
                onClick={() => clickedResult(resultado, color)}
                className={"result-card bg-" + color}
              >
                <div className="result-text">
                  <p>"[...] {trimmed} [...]"</p>
                </div>
                <div className="result-title">
                  <p>{resultado.title.toUpperCase()}</p>
                  <p>{secao}</p>
                </div>
              </div>
            );
          })}
        </div>
        <Stack
          className="pagination"
          style={{ marginTop: 30 }}
          spacing={2}
          alignItems="center"
        >
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChangePage}
            size="large"
          />
        </Stack>
      </div>
      <SidebarDireita bg="var(--preto)" />
    </div>
  );
}

export default Pesquisa;
