import "./vertical.scss";
import React, { useEffect, useRef } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";

import coracoes from "./Assets/s2s.png";
import celular from "./Assets/cel.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";

function Vertical3(props) {
  const elementRef = useRef(null);

  useEffect(() => {
    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  return (
    <div
      className={
        props.placeOn === "pagina-vertical-3"
          ? "vertical pagina-vertical-3 up"
          : "vertical pagina-vertical-3 down"
      }
    >
      <div className="vertical-left">
        <div className="menu-vertical-left">
          <a href="#fortalecendo">
            _Galera que quer chegar fortalecendo no amor
          </a>
          <a href="#apoio">
            _Galera que quer apoiar com algum serviço ou produto
          </a>
          <a href="#big-companies">_Big companies</a>
          <a href="#me-liga">_Me liga, me manda um telegrama</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => props.setPlaceOn("down-1")}
        ></div>
      </div>
      <div className="vertical-right">
        <div className="vertical-padding">
          {props.textinhos &&
            props.textinhos.map((t, i) => {
              if (t.sectionId === 3) {
                return (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "fortalecendo" && (
                        <img
                          alt="Corações"
                          className="img-right"
                          src={coracoes}
                        ></img>
                      )}
                      {t.tagId === "me-liga" && (
                        <img
                          alt="Celular"
                          className="img-right"
                          src={celular}
                        ></img>
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    </div>
                  </div>
                );
              }
              return null; // Add return statement here
            })}
        </div>
        <AnimatedPixels amount={100} />
      </div>
      <SidebarDireita bg="var(--azul)" />
    </div>
  );
}

export default Vertical3;
