import React, { useState, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import service from "../../../service";
import "./TextEditor.scss";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";

const TextEditor = () => {
  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [quill, setQuill] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await service.getAllPosts();
        if (response.data.length > 0) {
          setPosts(response.data);
          setCurrentPost(response.data[0]);
        }
      } catch (err) {
        console.error("Error fetching posts:", err);
      }
    })();
  }, []);

  useEffect(() => {
    if (currentPost && !quill) {
      const quillInstance = new Quill("#editor-container", {
        theme: "snow",
        placeholder: "Texto vai aqui",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image", "video", "formula"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        },
      });
      const pureText = currentPost.pureText;
      quillInstance.setText(pureText);
      setQuill(quillInstance);
    }
  }, [currentPost, quill]);

  const handlePostChange = (event) => {
    const postId = event.target.value;
    const selectedPost = posts.find((post) => post.id.toString() === postId);
    setCurrentPost(selectedPost);
    if (quill && selectedPost) {
      const pureText = selectedPost.pureText;
      quill.setText(pureText);
    }
  };

  const savePost = async () => {
    if (!currentPost || !quill) return;

    setIsLoading(true);

    const updatedPost = {
      ...currentPost,
      content: quill.getContents(),
      innerHTML: quill.root.innerHTML,
      pureText: quill.getText(),
    };

    try {
      await service.updatePost(updatedPost, sessionStorage.getItem("token"));
      alert(`Post "${updatedPost.title}" atualizado!`);
    } catch (err) {
      console.error(err);
      alert("Erro atualizando post: " + err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-container">
      <DashboardHeader />
      <div className="text-editor">
        <h1 className="title">Editor de Texto</h1>
        <div className="select-wrapper">
          <label>Texto:</label>
          <select onChange={handlePostChange} value={currentPost?.id || ""}>
            {posts.map((post) => (
              <option key={post.id} value={post.id}>
                {post.title}
              </option>
            ))}
          </select>
        </div>
        <div id="editor-container" />
        <button
          className="save-button"
          onClick={savePost}
          disabled={isLoading || !currentPost}
        >
          {isLoading ? "Salvando..." : "Salvar"}
        </button>
      </div>
    </div>
  );
};

export default TextEditor;
