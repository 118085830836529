import './Subnavs.scss';
import React from 'react';

function Sub2(props) {

    let estilo = {
        left: '-45vw', transition: 'all 1s'
    };
    (props.subOn === "show-2" ? estilo = { left: '5vw', transition: 'all 1s' } : estilo = { left: '-45vw', transition: 'all 1s' })

    function setPlace(position) {
        props.scrollToSection('seg', 'pagina-vertical-2', position);
        setTimeout(function () {
            props.setSecondaryIconColor("var(--rosa)");
        }.bind(this), 2300)
    }

    return (
        <div>
            {props.placeOn !== 'pagina-vertical-2' && (
                <div className="dropdown drop-2" style={estilo} onMouseEnter={() => props.showSub("show-2")} onMouseLeave={() => props.showSub("show-0")}>
                    <div className={"sub sub-azul a" + props.bg.substring(6, props.bg.length - 1)}>
                        <a href="#o-ciclo" onClick={() => setPlace('o-ciclo')}><span> &gt; Ciclo de criação</span></a>
                        <a href="#historia" onClick={() => setPlace('historia')}><span> &gt; Senta que lá vem história</span></a>
                        <a href="#edicoes" onClick={() => setPlace('edicoes')}><span> &gt; Edição atual</span></a>
                        <a href="#edicoes-anteriores" onClick={() => setPlace('edicoes-anteriores')}><span> &gt; Edições anteriores</span></a>
                        <a href="#webdocumentario" onClick={() => setPlace('webdocumentario')}><span> &gt; Webdocumentário</span></a>
                    </div>

                </div>
            )}
        </div>
    );

}

export default Sub2;
