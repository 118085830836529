import "./Register.scss";
import { useState } from "react";
import DashboardHeader from "../../../Components/DashboardHeader/DashboardHeader";
import service from "../../../service";

export default function Register(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    service
      .registerMod(
        { username: username, password: password },
        sessionStorage.getItem("token")
      )
      .then((response) => {
        alert("Administrador registrado.");
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="Register">
      <DashboardHeader />
      <div className="register-card">
        <h1 className="title">Registrar</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-1 input-wrapper">
            <label>Nome de usuário:</label>
            <input
              required
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-1 input-wrapper">
            <label>Senha:</label>
            <input
              required
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="register-button"
          >
            {isLoading ? "Registrando..." : "Registrar"}
          </button>
        </form>
      </div>
    </div>
  );
}
