import "./Recovery.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import service from "../../service";

const Recovery = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    service
      .recoverPassword({ email: email })
      .then(() => {
        alert("Email enviado.");
      })
      .catch((err) => {
        alert("Erro ao enviar email.");
        console.error("Recovery error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="password-recovery">
      <form onSubmit={handleSubmit}>
        <h2 className="custom-heading">Recuperação de Senha</h2>
        <label htmlFor="email">Digite seu email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Enviando email..." : "Enviar email"}
        </button>
        <div className="return-button">
          <Link to="/admin">Voltar</Link>
        </div>
      </form>
    </div>
  );
};

export default Recovery;
