import "./Login.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../../../Assets/logo-ecofoto.png";
import { Link } from "react-router-dom";

import service from "../../../service";

export default function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    service
      .login({ username: username, password: password })
      .then((response) => {
        sessionStorage.setItem("token", response.data.token);
        setIsLoading(false);
        navigate("/admin/home");
      })
      .catch((err) => {
        alert(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" className="logo" />
      <div className="login-card">
        <form onSubmit={handleSubmit}>
          <div className="mb-1 input-wrapper">
            <label>Login:</label>
            <input
              required
              type="text"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-1 input-wrapper">
            <label>Senha:</label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Carregando..." : "Entrar"}
          </button>
          <div className="forgot-password-div">
            <Link to="/recovery" >
              Esqueceu a senha?
            </Link>
          </div>

        </form>
      </div>
    </div>
  );
}
