const titles = {
    1: [
        "Álamo Pimentel", "Álamo Pimentel", "Alexandre Sobral", "Alexandre Sobral", "Angela Roumillac", "Angela Roumillac", "Angela Roumillac", "Angela Roumillac", "Bernardo Lo Bianco", "Bernardo Lo Bianco", "Bruno Leonardo Moraes", "Bruno Leonardo Moraes", "Daniel M Gorodicht", "Daniel M Gorodicht", "Diego Blanco Amorim", "Diego Blanco Amorim", "Ely Emerson S Costa", "Ely Emerson S Costa", "Guilherme F de Almeida", "Guilherme F de Almeida", "Guilherme F de Almeida", "Hudson Silva Malta", "Hudson Silva Malta", "Hudson Silva Malta", "Hudson Silva Malta", "Juliana F Cavalcanti", "Juliana F Cavalcanti", "Juliana Silva Fontoura", "Juliana Silva Fontoura", "Leonardo Cid L Pombo", "Leonardo Cid L Pombo", "Márcio José G Vieira", "Márcio José G Vieira", "Márcio José G Vieira", "Márcio José G Vieira", "Maria Angela G Baldissera", "Maria Angela G Baldissera", "Maria Angela G Baldissera", "Maria Angela G Baldissera"
    ],

    2: [
        "Elias Azevedo da Silva",
        "Nathália Ronfini de Almeida Lima",
        "Nathália Ronfini de Almeida Lima",
        "Nathália Ronfini de Almeida Lima",
        "Christian Jacques Henri Delon",
        "João Gabriel Rodrigues Teixeira da Costa",
        "Fernanda Rodrigues da Silva de Souza",
        "Ângela Roumillac",
        "Bernardo Lo Bianco",
        "Roberto Machado Alves",
        "Roberto Machado Alves",
        "Roberto Machado Alves",
        "Fabiano Pereira Lourenço Soares",
        "Suelen Lopes de Oliveira",
        "Marcelo Londono Alvarez",
        "Diego Blanco Amorim",
        "Tereza Baptista Cunha",
        "Ana Tereza Condé",
        "Felipe de Carvalho Borges",
        "Glaucia Aragão Torquato",
        "Guilherme F de Almeida",
        "Elaine Rosa Soares do Vale",
        "Ulisses Parente Souza",
        "Guilherme de Sá Pavarini Raj",
        "Evângelo Leal Gasos",
        "Guilherme Machado dos Santos Alves",
        "Radilson Carlos Gomes da Silva",
        "Elias Azevedo da Silva",
        "Radilson Carlos Gomes da Silva",
        "Marianna Barbosa Ramos",
        "Jeanine Mendes Gall",
        "Stefano Figalo",
        "André da Silva Telles",
        "Isabela Batista de Barros",
        "Bárbara Bergamaschi Novaes",
        "Stefano Figalo",
        "Bruno Soares Ferreira",
        "Ana Clara Carvalho",
        "Radilson Carlos Gomes da Silva"
    ],
    3: [
        "Andercelly Christofolli",
        "Andercelly Christofolli",
        "André da Silva Telles",
        "Carolina Paixão de Albuquerque",
        "Carolina Paixão de Albuquerque",
        "Clara Grivicich",
        "Durval Souza de Carvalho",
        "Durval Souza de Carvalho",
        "Fábio Gama Soares Evangelista",
        "Fábio Gama Soares Evangelista",
        "Fábio Gama Soares Evangelista",
        "Jhonatas Rodrigues Pereira",
        "Fábio Gama Soares Evangelista",
        "Américo Venceslau Freire Junior",
        "Jhonatas Rodrigues Pereira",
        "Josélia de Medeiros Frazão",
        "Josélia de Medeiros Frazão",
        "EcoFoto",
        "Leonardo Lima",
        "Leonardo Lima",
        "Leonardo Lima",
        "Luis Otávio de Oliveira",
        "Luis Otávio de Oliveira",
        "Luis Otávio de Oliveira",
        "Luiza Barreto de Carvalho",
        "Luiza Barreto de Carvalho",
        "Marcelo Aledi",
        "Marcelo Aledi",
        "Pedro Caetano Eboli Nogueira",
        "Pedro Caetano Eboli Nogueira",
        "Pedro Caetano Eboli Nogueira",
        "Rafael Pinto Soares",
        "Rafael Pinto Soares",
        "Rafael Pinto Soares",
        "Américo Venceslau Freire Junior",
        "Rute Silveira dos Santos",
        "Rute Silveira dos Santos",
        "Tatiane da Silva Portugal",
        "Tatiane da Silva Portugal"
    ],

    4: [
        "Amanda Alexandre Silva",
        "Brenno de Castro Barroso Alves",
        "Bruno Privatti Gonçalves Dias",
        "Carla Craveiro",
        "Carlos Henrique Alvaranga Barreto",
        "Eduardo Ribeiro",
        "Elisa Cristina Sá Fortes Clavery",
        "Gaia Caldas Vani",
        "Hevelin Costa",
        "Hevelin Costa",
        "EcoFoto",
        "Isabella de Magalhães Pedreira",
        "Katryn Kischlat Dias",
        "Leonardo Silva de Lima",
        "Luciana Valiatti de Almeida Pedroso",
        "Marcelle Fabiane de Manacés",
        "Mari Baldissera",
        "Rebecca Ferreira Lobo Andrade Maciel",
        "Renata da Silva Melo",
        "Renata Fontanetto"


    ],

    5: [
        "Leylianne Alves Vieira",
        "Andrea Amado de Cerqueira",
        "Leonardo de Almeida Soares Coelho",
        "Clarissa Ribeiro Silva",
        "Nilo dos Anjos Gomes",
        "Clarissa Ribeiro Silva",
        "Daniele Martins Mesquita Melo",
        "Lucas de Assis Andrade",
        "Luciana Valiatti de Almeida Pedroso",
        "Luciana Valiatti de Almeida Pedroso",
        "André da Silva Telles",
        "Cláudia D Elia",
        "André da Silva Telles",
        "André da Silva Telles",
        "Bruno Privatti",
        "Luiz Claudio de Carvalho Silva",
        "Luiz Claudio de Carvalho Silva",
        "Daniela Martins Mesquita Melo",
        "Carla Adelina Craveiro Silva",
        "Carla Adelina Craveiro Silva",

    ],

    6: [
        "Andreia Cristina",
        "Andreia Cristina",
        "Beto Fontes",
        "Beto Fontes",
        "Bruno Privatti",
        "Carlalie José",
        "Carlalie José",
        "Caroline Maia",
        "Caroline Maia",
        "Italo de Paula",
        "Jessica Evelyn",
        "Lu Valiatti",

        "Lu Valiatti",
        "Luzo Vinicius",
        "Luzo Vinicius",
        "Renan Santos",
        "Renan Santos",
        "Thiago Antunes",
        "Thiago Diniz Vale",
    ],

    7: [

    ],

    8: [
        "Ana Cristina Vieira Soares",
        "Ana Cristina Vieira Soares",
        "Ana Cristina Vieira Soares",
        "Ana Cristina Vieira Soares",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Ariádila Matos",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Eva Barcellos de Jesus",
        "Isabela Espindola",
        "Isabela Espindola",
        "Isabela Espindola",
        "Isabela Espindola",
        "Isabela Espindola",
        "Isabela Espindola",
        "João Paulo Mendes Roza Martins",
        "João Paulo Mendes Roza Martins",
        "João Paulo Mendes Roza Martins",
        "João Paulo Mendes Roza Martins",
        "João Paulo Mendes Roza Martins",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Jully Lourenço",
        "Laiane Teles",
        "Laiane Teles",
        "Laiane Teles",
        "Laiane Teles",
        "Laiane Teles",
        "Laiane Teles",
        "Louise P.",
        "Louise P.",
        "Louise P.",
        "Louise P.",
        "Louise P.",
        "Louise P.",
        "Marcella Sayonara",
        "Marcella Sayonara",
        "Marcella Sayonara",
        "Marcella Sayonara",
        "Marcella Sayonara",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Mellina Conteíro",
        "Priscila Gomes",
        "Priscila Gomes",
        "Priscila Gomes",
        "Priscila Gomes",
        "Priscila Gomes",
        "Priscila Gomes",
        "Thaiane Oliveira",
        "Thaiane Oliveira",
        "Thaiane Oliveira",
        "Thaiane Oliveira",
        "Thaiane Oliveira",
        "Thaiane Oliveira"
    ]

};

export default titles;
