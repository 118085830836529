import "./vertical.scss";
import React, { useEffect, useRef } from "react";
import SidebarDireita from "../Sidebar-Direita/Sidebar-Direita";

import ufrjlogo from "./Assets/UFRJlogo.png";
import camera from "./Assets/Camera.png";
import tv from "./Assets/Tv2.png";
import galera from "./Assets/galera.png";

import seta from "./Assets/seta-baixo.png";
import AnimatedPixels from "../AnimatedPixels/AnimatedPixels";

function Vertical1(props) {
  const elementRef = useRef(null);

  useEffect(() => {
    if (props.placeOn === "pagina-vertical-1") {
      setTimeout(() => {
        if (elementRef.current) {
          elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1500);
    }
  }, [props.placeOn]);

  return (
    <div
      className={
        props.placeOn === "pagina-vertical-1"
          ? "vertical pagina-vertical-1 up"
          : "vertical pagina-vertical-1 down"
      }
    >
      <div className="area-seta-pra-baixo">
        <img
          src={seta}
          alt="seta para abaixar a tela"
          className="seta-pra-baixo"
        ></img>
      </div>
      <div className="vertical-left">
        <div className="menu-vertical-left">
          <a href="#sobre-nos">_Sobre nós</a>
          <a href="#missoes">_Missões</a>
          <a href="#como-faz">_Como que faz pra realizar tudo isso?</a>
          <a href="#nossa-galera">_Nossa galera</a>
        </div>
        <div
          className="seta-pro-topo"
          onClick={() => props.scrollToSection("primeiro", "down-1")}
        ></div>
      </div>
      <div className="vertical-right">
        <div className="vertical-padding">
          {props.textinhos &&
            props.textinhos.map((t, i) => {
              if (t.sectionId === 1) {
                return (
                  <div key={i}>
                    <div
                      id={t.tagId}
                      style={{ color: "var(--roxo)" }}
                      className="vertical-title bg-branco"
                    >
                      {t.title}
                    </div>
                    <div>
                      {t.tagId === "sobre-nos" && (
                        <img
                          alt="UFRJ"
                          src={ufrjlogo}
                          className="float-right vertical-logo"
                        ></img>
                      )}
                      {t.tagId === "missoes" && (
                        <img
                          alt="Câmera fotográfica"
                          src={camera}
                          className="float-right vertical-camera"
                        ></img>
                      )}
                      {t.tagId === "como-faz" && (
                        <img
                          alt="Televisão"
                          src={tv}
                          className="vertical-tv float-left"
                        ></img>
                      )}
                      {t.tagId === "nossa-galera" && (
                        <img
                          alt="galera"
                          src={galera}
                          className="float-right vertical-logo"
                        ></img>
                      )}
                      <div
                        className="vertical-text"
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
        <AnimatedPixels amount={100} />
      </div>
      <SidebarDireita bg="var(--verde)" />
    </div>
  );
}

export default Vertical1;
