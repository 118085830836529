import "./section.scss";
import React, { useEffect, useState } from "react";

import Marquee from "react-easy-marquee";

import role from "./Assets/nosso-role.png";
import ciclo from "./Assets/ciclo.png";
import chega from "./Assets/chega-mais.png";

import subAmarelo from "./Assets/sub-amarelo.png";
import subAzul from "./Assets/sub-azul.png";
import subRosa from "./Assets/sub-rosa.png";

import { useInView } from "react-intersection-observer";

import { TypeAnimation } from "react-type-animation";

function Section(props) {
  const [verdeEntrou, setVerdeEntrou] = useState(0);
  const [rosaEntrou, setRosaEntrou] = useState(0);
  const [azulEntrou, setAzulEntrou] = useState(0);
  const [typing, setTyping] = useState(false);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (inView === true) {
      if (props.bg === "var(--verde)") {
        if (verdeEntrou < 1) {
          let temp = verdeEntrou + 1;
          setVerdeEntrou(temp);

          setTimeout(function () {
            //Start the timer
            setTyping(true); //After 1 second, set render to true
          }, 400);
        }
        props.setSecondaryColor("var(--rosa)");
        props.setSecondaryIconColor("var(--rosa)");
      } else if (props.bg === "var(--rosa)") {
        if (rosaEntrou < 1) {
          let temp = rosaEntrou + 1;
          setRosaEntrou(temp);
        }
        props.setSecondaryColor("var(--azul)");
        props.setSecondaryIconColor("var(--azul)");
      } else if (props.bg === "var(--azul)") {
        if (azulEntrou < 1) {
          let temp = azulEntrou + 1;
          setAzulEntrou(temp);
        }
        props.setSecondaryColor("var(--roxo)");
        props.setSecondaryIconColor("var(--roxo)");
      }
    }
  }, [inView, azulEntrou, props, rosaEntrou, verdeEntrou]);

  function setPlace(section, position, pagina, color) {
    props.scrollToSection(section, pagina);
    props.setPosition(position);
    setTimeout(function () {
      props.setSecondaryIconColor("var(--" + color + ")");
    }, 2300);
  }

  return (
    <section
      ref={ref}
      style={{ backgroundColor: props.bg }}
      className={
        props.bg === "var(--azul)"
          ? "bg-img-azul"
          : props.bg === "var(--rosa)"
            ? "bg-img-rosa"
            : "bg-img-verde"
      }
    >
      {/*  -----------------------               PRIMEIRA SECAO             ------------------------   */}

      {props.letreiro === "role" && (
        <>
          <div className="letreiro">
            <Marquee duration={15000} axis="y" height="100%">
              <img alt="Nosso rolê" src={role} className="imagem" />
            </Marquee>
          </div>
          <div className="meio">
            <div className="titulo">
              <img
                alt="O que é ECOFOTO?"
                src={subAmarelo}
                className={verdeEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div className="div-texto div-txt-verde">
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "nosso-role") {
                    return (
                      <div
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return <p></p>;
                  }
                })}
            </div>
          </div>
          <div className="direita">
            <div className={verdeEntrou >= 1 ? "mao" : ""}>
              {typing === true && (
                <>
                  <div className={verdeEntrou >= 1 ? "texto-mao mao-1" : "hide"}>
                    <a
                      href="#sobre-nos"
                      className="no-underline"
                      onClick={() => setPlace("primeiro", "sobre-nos", "pagina-vertical-1")}
                    >
                      <TypeAnimation sequence={["_Sobre nós"]} speed={10} />
                    </a>
                  </div>
                  <div className={verdeEntrou >= 1 ? "texto-mao mao-2" : "hide"}>
                    <a
                      href="#missoes"
                      className="no-underline"
                      onClick={() => setPlace("primeiro", "missoes", "pagina-vertical-1")}
                    >
                      <TypeAnimation sequence={[" _Nossas missões"]} speed={10} />
                    </a>
                  </div>
                  <div className={verdeEntrou >= 1 ? "texto-mao mao-3" : "hide"}>
                    <a
                      href="#como-faz"
                      className="no-underline"
                      onClick={() => setPlace("primeiro", "como-faz", "pagina-vertical-1")}
                    >
                      <TypeAnimation
                        sequence={["_Como que faz pra realizar tudo isso?"]}
                        speed={10}
                      />
                    </a>
                  </div>
                  <div className={verdeEntrou >= 1 ? "texto-mao mao-4" : "hide"}>
                    <a
                      href="#nossa-galera"
                      className="no-underline"
                      onClick={() => setPlace("primeiro", "nossa-galera", "pagina-vertical-1")}
                    >
                      <TypeAnimation sequence={[" _Nossa galera"]} speed={10} />
                    </a>
                  </div>

                </>
              )}
            </div>
          </div>
        </>
      )}

      {/*  -----------------------               SEGUNDA SECAO             ------------------------   */}

      {props.letreiro === "ciclo" && (
        <>
          <div className="letreiro">
            <Marquee duration={15000} axis="y" height="100%">
              <img alt="Ciclo de criação" src={ciclo} className="imagem" />
            </Marquee>
          </div>
          <div className="meio">
            <div className="titulo">
              <img
                alt="Uma imersão artística"
                src={subRosa}
                className={rosaEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div className="div-texto div-txt-rosa">
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "ciclo-criacao") {
                    return (
                      <div
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return <p></p>;
                  }
                })}
            </div>
          </div>
          <div className="direita">
            <div className="direita-polaroid">
              <div
                onClick={() => {
                  setPlace("seg", "historia", "pagina-vertical-2", "rosa");
                  window.location.href = "#historia";
                }}
                className={rosaEntrou >= 1 ? "polaroid-topo" : ""}
              ></div>
              <div
                onClick={() => {
                  setPlace("seg", "edicoes-anteriores", "pagina-vertical-2", "rosa");
                  window.location.href = "#edicoes-anteriores";
                }}
                className={rosaEntrou >= 1 ? "polaroid-meio" : ""}
              ></div>
              <div
                onClick={() => {
                  setPlace("seg", "webdocumentario", "pagina-vertical-2", "rosa");
                  window.location.href = "#webdocumentario";
                }}
                className={rosaEntrou >= 1 ? "polaroid-baixo" : ""}
              ></div>

            </div>
          </div>
        </>
      )}

      {/*  -----------------------               TERCEIRA SECAO             ------------------------   */}

      {props.letreiro === "chega" && (
        <>
          <div className="letreiro">
            <Marquee duration={15000} axis="y" height="100%">
              <img alt="Chega mais" src={chega} className="imagem" />
            </Marquee>
          </div>
          <div className="meio">
            <div className="titulo">
              <img
                alt="Como ajudar o projeto?"
                src={subAzul}
                className={azulEntrou >= 1 ? "entrou-na-tela" : ""}
              />
            </div>
            <div className="div-texto div-txt-azul">
              {props.textinhos &&
                props.textinhos.map((t, i) => {
                  if (t.tagId === "chega-mais") {
                    return (
                      <div
                        className={`texto ${props.secondary}`}
                        dangerouslySetInnerHTML={{ __html: t.innerHTML }}
                      />
                    );
                  } else {
                    return <p></p>;
                  }
                })}
            </div>
          </div>
          <div className="direita">
            <div className={azulEntrou >= 1 ? "fita-direita" : ""}>
              <div
                onClick={() => {
                  setPlace("ter", "fortalecendo", "pagina-vertical-3", "azul");
                  window.location.href = "#fortalecendo";
                }}
                className={azulEntrou >= 1 ? "fita-direita-1" : ""}
              ></div>
              <div
                onClick={() => {
                  setPlace("ter", "apoio", "pagina-vertical-3", "azul");
                  window.location.href = "#apoio";
                }}
                className={azulEntrou >= 1 ? "fita-direita-2" : ""}
              ></div>
              <div
                onClick={() => {
                  setPlace("ter", "big-companies", "pagina-vertical-3", "azul");
                  window.location.href = "#big-companies";
                }}
                className={azulEntrou >= 1 ? "fita-direita-3" : ""}
              ></div>
              <div
                onClick={() => {
                  setPlace("ter", "me-liga", "pagina-vertical-3", "azul");
                  window.location.href = "#me-liga";
                }}
                className={azulEntrou >= 1 ? "fita-direita-4" : ""}
              ></div>
            </div>
          </div>

        </>
      )}
    </section>
  );
}

export default Section;
