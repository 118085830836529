import React, { useState, useEffect, useRef } from "react";
import "./Eye.scss";

export default function Eye() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const eyeRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (eyeRef.current) {
        const { left, top, width, height } =
          eyeRef.current.getBoundingClientRect();
        const eyeCenterX = left + height / 2;
        const eyeCenterY = top + width / 2;

        const deltaX = e.pageX - eyeCenterX;
        const deltaY = e.pageY - eyeCenterY;

        const angle = Math.atan2(deltaY, deltaX);
        const radius = width / 2;
        const pupilRadius = width * 0.4;
        const maxDistance = radius - pupilRadius;
        const distance = Math.min(
          maxDistance,
          Math.sqrt(deltaX ** 2 + deltaY ** 2)
        );
        const pupilX = distance * Math.cos(angle);
        const pupilY = distance * Math.sin(angle);
        setMousePosition({ x: pupilX, y: pupilY });
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Função para limpar o href e recarregar a página
  const handleLogoClick = () => {
    // Limpa qualquer fragmento ou parâmetro da URL
    window.history.replaceState(null, null, window.location.pathname);
    
    // Recarrega a página
    window.location.reload();
  };

  return (
    <div className="eye" ref={eyeRef} onClick={handleLogoClick}>
      <div
        className="pupil"
        style={{
          transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
        }}
      >
        <div className="eye-light"></div>
      </div>
    </div>
  );
}
