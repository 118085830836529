import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppWrapper from "./App"; // Certifique-se de importar o AppWrapper
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
